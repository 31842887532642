import qs from 'qs'

const fields = [
  'title',
  'richText'
]

export const queryBiennialPage = () => {
  const query = qs.stringify(
    {
      fields,
      populate: {
        ...queryFragmentSeo
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `biennial-page?${query}`
}
